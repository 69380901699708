<template>
  <content-template :menuName="`${$t('dashboard.hello')}, ${user.name}`" :dashboard="true">
    <template #dashboard>
      <div class="s-mt-16">
        <div class="s-fullWidth">
          <div class="s-flex" :style="`${isMobile ? 'flex-wrap:wrap' : ''}`">
            <div class="s-fullWidth s-mb-10" :class="isMobile? '':'s-mlr-8'">
              <div class="s-flat-box ">
                <p class="s-weight-600 s-text-center s-ht-36">
                  Risk Identification
                </p>
                <div id="chart">
                  <apexchart
                    type="radialBar"
                    height="180"
                    :options="chartOptions2"
                    :series="series2"
                  ></apexchart>
                </div>
              </div>
            </div>
            <div class="s-fullWidth s-mb-10" :class="isMobile? '':'s-mlr-8'">
              <div class="s-flat-box ">
                <p class="s-weight-600 s-text-center s-ht-36">
                  Risk Identification & Reduction
                </p>
                <div id="chart">
                  <apexchart
                    type="radialBar"
                    height="180"
                    :options="chartOptions3"
                    :series="series3"
                  ></apexchart>
                </div>
              </div>
            </div>
            <div class="s-fullWidth s-mb-10" :class="isMobile? '':'s-mlr-8'">
              <div class="s-flat-box">
                <p class="s-weight-600 s-text-center s-ht-36">Risk Reduction</p>
                <div id="chart">
                  <apexchart
                    type="radialBar"
                    height="180"
                    :options="chartOptions4"
                    :series="series4"
                  ></apexchart>
                </div>
              </div>
            </div>
            <div class="s-fullWidth s-mb-10" :class="isMobile? '':'s-mlr-8'">
              <div class="s-flat-box">
                <p class="s-weight-600 s-text-center s-ht-36">
                  Preparedness & Response
                </p>
                <div id="chart">
                  <apexchart
                    type="radialBar"
                    height="180"
                    :options="chartOptions5"
                    :series="series5"
                  ></apexchart>
                </div>
              </div>
            </div>
            <div class="s-fullWidth s-mb-10" :class="isMobile? '':'s-mlr-8'">
              <div class="s-flat-box">
                <p class="s-weight-600 s-text-center s-ht-36">
                  Recovery & Rehabilitation
                </p>
                <div id="chart">
                  <apexchart
                    type="radialBar"
                    height="180"
                    :options="chartOptions6"
                    :series="series6"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="s-fullWidth s-flex s-mtb-16 s-wrap" >
          <div :class="`${!isMobile ? 's-wd-70-p' : 's-fullWidth'}`">
            <div class="s-flat-box " :class="isMobile? '':'s-mr-16'">
              <div style="margin-top: -20px">
                <h3 class="s-text-text">{{$t('dashboard.summaryOf')}} {{$t('dashboard.DRMCycle')}}</h3>
              </div>
              <div class="s-overflow-scroll">
                <div id="chart">
                  <apexchart
                   v-if="!isMobile"
                    type="bar"
                    height="300"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                  <apexchart
                   v-if="isMobile"
                    type="bar"
                    height="300"
                    width="600"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
          <div :class="`s-flat-box ${isMobile ? 's-mt-16 s-fullWidth': 's-column-4 s-mt-10'}`">
            <div>
              <div style="margin-top: -20px">
                <h3 class="s-text-text">{{$t('dashboard.summaryOf')}} {{$t('dashboard.uploads')}}</h3>
              </div>
              <div class="s-flex" :style="`${isMobile ? 'flex-wrap:wrap' : ''}`">
                <div class="s-column-2">
                  <p>{{$t('sidebar.resourceDatabase')}}</p>
                  <h3>{{ uploads.brrd }}</h3>
                </div>
                <div class="s-column-2">
                  <p>{{$t('sidebar.feedbackCollection')}}</p>
                  <h3>{{ uploads.feedback }}</h3>
                </div>
              </div>
              <div class="s-flex" :style="`${isMobile ? 'flex-wrap:wrap' : ''}`">
                <div class="s-column-2">
                  <p>{{$t('sidebar.eventPublication')}}</p>
                  <h3>{{ uploads.event }}</h3>
                </div>
                <div class="s-column-2">
                  <p>{{$t('sidebar.educationalContent')}}</p>
                  <h3>{{ uploads.edccontent }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <sidebar />
  </content-template>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import sidebar from "@/components/dashboard/sidebar";
import Sidebar from "./sidebar.vue";
import VueApexCharts from "vue-apexcharts";
import { mapActions, mapGetters } from "vuex";
import form from "@/mixins/form";
export default {
  components: { ContentTemplate, sidebar, Sidebar, apexchart: VueApexCharts },
  mixins: [form],
  data: () => ({
    openDrop: false,
    item: "",
    series: [],
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
      },
      colors: ["#658CC7", "#EFE4DE", "#09336F", "#FFCB69"],
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: function (val) {
            return val + "%";
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "%";
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
    series2: [0],
    chartOptions2: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: [""],
      colors: ["#658CC7", "#EFE4DE", "#455349", "#FFCB69"],
    },
    series3: [0],
    chartOptions3: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: [""],
      colors: ["#FFCB69"],
    },
    series4: [0],
    chartOptions4: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: [""],
      colors: ["#455349", "#FFCB69"],
    },
    series5: [0],
    chartOptions5: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: [""],
      colors: ["#FFCB69"],
    },
    series6: [0],
    chartOptions6: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: [""],
      colors: ["#EFE4DE", "#455349", "#FFCB69"],
    },
  }),
  computed: {
    ...mapGetters({
      isMobile: "getIsMobile",
      dashboard: "dashboard/getDashboard",
      user: "auth/getUser",
    }),
    uploads(){
      return this.dashboard.summary_of_upload || {}
    }
  },
  async mounted() {
    await this.initData();
  },
  methods: {
    ...mapActions({
      setDashboard: "dashboard/setDashboardData",
      setUserData: "auth/setUserData",
    }),

    async initData() {
      this.setLoadingPage(true)
      await this.setDashboard();
      await this.setUserData();
    
      this.dashboard.cycle_phase_collection.forEach((value) => {
        this.chartOptions.xaxis.categories.push(value);
      });

      this.series = this.dashboard.summary_of_drm;

      this.series2 = [this.dashboard.cycle_phase["Risk Identification"]];
      this.series3 = [
        this.dashboard.cycle_phase["Risk Identification and Reduction"],
      ];
      this.series4 = [this.dashboard.cycle_phase["Risk Reduction"]];
      this.series5 = [this.dashboard.cycle_phase["Preparedness and Response"]];
      this.series6 = [this.dashboard.cycle_phase["Recovery and Rehabilitation"],];
      this.setLoadingPage(false)
    },
  },
};
</script>
